@import "color_set";

$full-width: 1440px;

@mixin verticalMiddle {
  & > * {
    vertical-align: middle;
    display: inline-block;
  }
  &:after {
    content: '';
    width: 0;
    height: 100%;
    vertical-align: middle;
    display: inline-block;
  }
}

// after 선택자 때문에 빈 한줄이 생길 수 있음 고정높이가 아니면 음수마진값으로 조정해야한다
@mixin justify {
  text-align: justify;
  & > * {
    display: inline-block;
  }
  &::after {
    content: '';
    display: inline-block;
    width: 100%;
    height: 0;
    visibility: hidden;
  }
}

// 일반적인 페이지 형태중 하나(쓰는 곳 참조)
@mixin theme1Content($horizontal-padding: 52px, $isVerticalMiddle: true) {
  margin-top: 82px;
  padding: 0 $horizontal-padding;
  .header {
    font: {
      weight: bold;
      size: 64px;
    }

    color: $smile-red2;
    @if $isVerticalMiddle {
      @include verticalMiddle;
    }
    img {
      margin-left: 82px - $horizontal-padding;
    }
    .header-title {
      margin-left: 31px;
    }
  }
}

// 위에것을 사진인화 페이지에서 쓰는 변형 스타일
@mixin theme1PhotoPrint($horizontal-padding: 52px) {
  margin-top: 82px;
  padding-left: $horizontal-padding;
  padding-right: $horizontal-padding;
  .header {
    display: flex;
    & > * {
      display: block;
    }
    justify-content: space-between;
    color: $smile-red2;
    font: {
      weight: bold;
      size: 64px;
    }
    .header-title {
      margin-left: 82px - $horizontal-padding;
      img {
        vertical-align: middle;
      }
      .main-title {
        vertical-align: middle;
        margin-left: 31px;
      }
      .sub-title {
        vertical-align: bottom;
        font-size: 43px;
      }
    }
    .page-notice {
      display: block;
      color: #606060;
      vertical-align: bottom;
      margin-left: 1em;
      font: {
        weight: normal;
        size: 43px;
      }
      &:after {
        content: '';
        display: inline-block;
        width: 0;
        height: 100%;
        vertical-align: bottom;
      }
    }
  }
}

// 공통 사용 텝 스타일(jquery-ui 이용한)
@mixin tabs($tab-num: 2, $tab-contents-w-padding: 52px) {
  color: $black-2;
  background-color: white;
  font: {
    size: 44px;
    weight: normal;
  }
  .tabs-header {
    display: flex;
    flex-direction: row;
    font: {
      size: 48px;
      weight: bold;
    }
    color: $gray;
    li {
      display: block;
      width: 1/$tab-num * 100%;
      width: calc(100% / #{$tab-num});
      text-align: center;
      padding: 45px 0;
      border: {
        bottom: 6px solid #e6e6e6;
      }
      &.ui-state-active {
        border-bottom-color: $smile-red2;
        color: $smile-red2;
      }
      a {
        display: block;
        &:focus {
          outline: none;
        }
      }
    }
  }
  .tab-contents {
    padding: 0 $tab-contents-w-padding;
    display: none;
  }
}

@mixin imageCenterWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    display: block;
    max-width: 100%;
    max-height: 100%;
  }
}

@mixin thumbnailArea($width:274px) {
  box-sizing: border-box;
  width: $width;
  min-width: $width;
  background: {
    repeat: no-repeat;
    size: contain;
    origin: content-box;
    position: center;
  }
}

// flex 이용한 holyGrail Layout은 flex의 하위 호환으로 볼 수 있는 display: -webkit-box로도 불가능
// 그러므로 좀 더 이전 방식으로 믹스인 작성.

// position relative - absolute 이용한 성배 레이아웃.
// 컨테이너 상하 padding을 줄 경우 개별 요소에도 padding값을 적용해야 하므로 padding값 필요.
@mixin holyGrailLayout1($left, $left-width, $center, $right, $right-width, $padding-top: 0, $padding-bottom: 0) {
  position: relative;
  #{$left}, #{$right} {
    box-sizing: border-box;
    position: absolute;
    height: 100%;
    padding-top: $padding-top;
    padding-bottom: $padding-bottom;
  }
  #{$left} {
    left: 0;
    top: 0;
    width: $left-width;
  }
  #{$center} {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding-left: $left-width;
    padding-right: $right-width;
  }
  #{$right} {
    right: 0;
    top: 0;
    width: $right-width;
  }
}

//  float 이용한 성배 레이아웃, container 안에 center left right 순서대로 구성되어 있어야 한다.
//  이 케이스의 경우 특정 수치의 height값을 줘야 한다.
//  https://stackoverflow.com/questions/7580005/css-fixed-width-dynamic-width-children-filling-100-parent-width
@mixin holyGrailLayout2($left, $left-width, $center, $right, $right-width) {
  box-sizing: border-box;
  padding-left: $left-width;
  padding-right: $right-width;
  height: $height;

  #{$left}, #{$center}, #{$right} {
    box-sizing: border-box;
    position: relative;
    height: $height;
    float: left;
  }
  #{$center} {
    width: 100%;
  }
  #{$left} {
    width: $left-width;
    right: $left-width;
    margin-left: -100%;
  }
  #{$right} {
    width: $right-width;
    margin-right: -$right-width;
  }
}

// 일반적인 팝업형태(임시)
@mixin smCommonPopup($popup-width: 1208px, $popup-height: 2007px, $popup-header-height: 167px) {
  $popup-body-height: $popup-height - $popup-header-height;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: $popup-width;
  height: $popup-height;
  background-color: white;
  border-radius: 30px;
  font-size: 44px;
  overflow: hidden;
  @include panelShadow;
  .popup-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    height: $popup-header-height;
    border-bottom: 3px solid #e8e8e8;
    .popup-title {
      font-size: 62px;
      font-weight: bold;
      color: $smile-red2;
      padding-left: 62px;
    }
    .popup-close-btn {
      padding-right: 48px;
    }
  }
  .popup-body {
    height: $popup-body-height;
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
}

// 일반적인 패널shadow
@mixin panelShadow {
  box-shadow: 1px 6px 12px 1px rgba(222, 222, 222, 1);
}

@mixin simpleHead{
  #frame {
    padding:90px 100px 0 100px;
    $font-size: 44px;
    color: $black-2;
    font-size: $font-size;
    .head{
      height: 100px;
      padding-bottom: 98px;
      width: 100%;
      border-bottom: 2px solid #c6c6c6;
      img {
        width:100px;
        float:left;
      }
      h1{
        float:left;
        margin:20px;
        font-weight: bold;
        font-size: 66px;
        color: #fc4a4f;
      }
    }
    p.main_text{
      font-size: 60px;
      line-height: 1.4;
      text-align: left;
    }
    .simple_input_box{
      width:92%;
      margin-top:16px;
      border-radius: 10px;
      background-color: #f9f9f9;
      border: solid 1px #bababa;
      font-size: 50px;
      padding: 1em;
    }
    .simple_input_box::placeholder{
      color: #bababa;
    }
    .simple_btn_red{
      font-size: 60px;
      margin-top:70px;
      line-height: 2.9;
      height:190px;
    }
    .simple_btn_red.loading{
      background-color: #f7f7f7;
      color:#aeaeae;
      border: solid 1px #aeaeae;
    }
  }
}

@mixin simpleBottom{
  #frame {
    .bottom{
      text-align: center;
      margin-top: 100px;
      width: 116%;
      margin-left: -8%;
      height: 200px;
      line-height: 4.1;
      background-color: #f2f2f2;
      .return{
        color: #fc4a4f;
      }
      .msg{
        color: #ffffff; display:none;
      }
    }
    .bottom.success{
      line-height: 1.5;
      background-color: #a3d847;
      .msg{
        padding-top: 26px;
      }
    }
    .bottom.fail{
      background-color: #ff685d;
    }
    .bottom.success, .bottom.fail{
      .return{
        display:none;
      }
      .msg{
        display:block;
      }
    }
  }
}