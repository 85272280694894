@import "mixin";

$menu-width: 1440px;
$logo-top-margin: 208px;
$close-btn-size: 59px;
$close-btn-padding: $close-btn-size;
$close-btn-top-margin: 84px;
$close-btn-left-margin: 64px;
$top-header-height: $close-btn-size + $close-btn-top-margin * 1.5;

#sidebar {
  background: url('/resource/image/drawer/menu_bg1.jpg');
  width: $menu-width;
  position: fixed;
  top: 0;
  bottom: 0;
  left: -1440px;
  padding-top: 25px;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    // Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ff684d+0,ff684d+14,fd654e+19,fe6651+20,fe6553+24,fc6351+24,fd6456+29,fc6256+38,fb6159+39,fa5f5b+50,fa5e5f+53,f75c60+62,f85a68+76,f55966+77,f6596a+80,f65769+84,f5586d+87,f6576d+100,fcd5da+100
    background: rgb(255,104,77); /* Old browsers */
    background: -moz-linear-gradient(top, rgba(255,104,77,0.7) 0%, rgba(255,104,77,0.7) 14%, rgba(253,101,78,0.7) 19%, rgba(254,102,81,0.7) 20%, rgba(254,101,83,0.7) 24%, rgba(252,99,81,0.7) 24%, rgba(253,100,86,0.7) 29%, rgba(252,98,86,0.7) 38%, rgba(251,97,89,0.7) 39%, rgba(250,95,91,0.7) 50%, rgba(250,94,95,0.7) 53%, rgba(247,92,96,0.7) 62%, rgba(248,90,104,0.7) 76%, rgba(245,89,102,0.7) 77%, rgba(246,89,106,0.7) 80%, rgba(246,87,105,0.7) 84%, rgba(245,88,109,0.7) 87%, rgba(246,87,109,0.7) 100%, rgba(252,213,218,0.7) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(255,104,77,0.7) 0%,rgba(255,104,77,0.7) 14%,rgba(253,101,78,0.7) 19%,rgba(254,102,81,0.7) 20%,rgba(254,101,83,0.7) 24%,rgba(252,99,81,0.7) 24%,rgba(253,100,86,0.7) 29%,rgba(252,98,86,0.7) 38%,rgba(251,97,89,0.7) 39%,rgba(250,95,91,0.7) 50%,rgba(250,94,95,0.7) 53%,rgba(247,92,96,0.7) 62%,rgba(248,90,104,0.7) 76%,rgba(245,89,102,0.7) 77%,rgba(246,89,106,0.7) 80%,rgba(246,87,105,0.7) 84%,rgba(245,88,109,0.7) 87%,rgba(246,87,109,0.7) 100%,rgba(252,213,218,0.7) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(255,104,77,0.7) 0%,rgba(255,104,77,0.7) 14%,rgba(253,101,78,0.7) 19%,rgba(254,102,81,0.7) 20%,rgba(254,101,83,0.7) 24%,rgba(252,99,81,0.7) 24%,rgba(253,100,86,0.7) 29%,rgba(252,98,86,0.7) 38%,rgba(251,97,89,0.7) 39%,rgba(250,95,91,0.7) 50%,rgba(250,94,95,0.7) 53%,rgba(247,92,96,0.7) 62%,rgba(248,90,104,0.7) 76%,rgba(245,89,102,0.7) 77%,rgba(246,89,106,0.7) 80%,rgba(246,87,105,0.7) 84%,rgba(245,88,109,0.7) 87%,rgba(246,87,109,0.7) 100%,rgba(252,213,218,0.7) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  }
  .sidebar-wrapper {
    position: relative;
    height: 100%;
    text-align: center;
    color: white;
    font-size: 55px;
    * {
      color: white;
      font-size: inherit;
    }
    @mixin  vHardBorder {
      position: relative;
      &:not(:first-child):before {
        content: "";
        position: absolute;
        left: 0;
        top: 25%;
        background: rgba(255, 255, 255, 0.65);
        height: 50%;
        width: 2px;
      }
    }
    @mixin topBorder {
      border-top: 2px solid rgba(255, 255, 255, 0.65);
    }
    #drawerCloseBtn {
      display: inline-block;
      position: absolute;
      left: 64px - $close-btn-padding;
      padding: $close-btn-padding;
    }
    //.sidebar-header {
    //  position: absolute;
    //  width: 100%;
    //  left: 0;
    //  top: -$top-header-height;
    //  text-align: left;
    //  &.fixed {
    //    position: fixed;
    //    top: 0;
    //  }
    //  #drawerCloseBtn {
    //    display: inline-block;
    //    margin-left: 64px - $close-btn-padding;
    //    margin-top: 84px - $close-btn-padding;
    //    padding: $close-btn-padding;
    //  }
    //}
    .sidebar-body {
      overflow: auto;
      height: 100%;
      padding: 0 108px;
      .smile-logo {
        width: 240px;
        height: 296px;
        margin-top:35px;
      }
      .login-info {
        margin-top: 42px;
        font: {
          size: 66px;
          weight: bold;
        }
        .membership{
          width:200px;
          height:52px;
          border-radius: 40px;
          padding: 12px;
          background-color: rgba(255, 255, 255, 0.3);
          font-size:48px;
          color:#ffffff;
          margin: auto auto 42px;
        }
        img{
          vertical-align: middle;
        }
        span{
          margin-top: 42px;
        }
      }
      .user-info {
        margin-top: 99px;
        margin-bottom: 93px;
        text-align: center;
        font-size: 0px; // inline 블록간 간격 0으로 하기 위해
        .user-info-field {
          white-space: normal;
          display: inline-block;
          position: relative;
          padding: 0 95px;
          .field-name {
            color: #ffe2dc;
            font: {
              size: 55px;
            }
          }
          .field-value {
            margin-top: 47px;
            font: {
              size: 80px;
              weight: bold;
            }
          }
          @include vHardBorder;
        }
      }
      .login-btn-area {
        margin: 86px 0;
        * {
          vertical-align: middle;
        }
        a {
          display: inline-block;
          padding: 20px 60px;
          background-color: rgba(255, 255, 255, 0.17);
          border: {
            radius: 60px;
            color: rgba(255, 255, 255, 0.7);
            width: 2px;
            style: solid;

          }
        }
        .bar {
          display: inline-block;
          margin: 0 60px;
          background: rgba(255, 255, 255, 0.65);
          width: 2px;
          height: 1em;
        }
      }
      .menu-btn-area {
        position: relative;
        padding: 45px 0;
        @include topBorder;
        table {
          position: relative;
          width: 1224px;
          height: 1023px;
          table-layout: fixed;
          box-sizing: border-box;
          border-collapse: collapse;
          tbody {
            tr {
              td {
                transition: background-color 0.1s;
                &.touched {
                  background-color: rgba(183, 183, 183, 0.1);
                }
                -webkit-tap-highlight-color: transparent;
                background-color: rgba(255, 255, 255, 0.17);
                vertical-align: bottom;
                padding-bottom: 76px;
                border: {
                  width: 2px;
                  style: solid;
                  color: rgba(255, 255, 255, 0.7);
                }
                color: white;
                font-size: 52px;
                text-align: center;
                img {
                  display: inline-block;
                  margin-bottom: 33px;
                }
              }
            }
          }
        }
      }
      .fix-height-row {
        @include topBorder;
        @include verticalMiddle;
        font-size: 0px;
        * {
          font: {
            size: 55px;
            weight: bold;
          }
        }
        height: 185px;
        .margin-left-37 {
          margin-left:37px;
        }
        .half-width {
          display: inline-block;
          width: 50%;
          height: 100%;
          @include verticalMiddle;
          @include vHardBorder;
        }
        a {
          @include verticalMiddle;
        }
      }
      .aside-footer {
        * {
          font: {
            size: 43px;
            weight: normal;
          }
        }
      }
    }
    .sidebar-body-v2 {
      overflow: auto;
      height: 100%;
      padding: 0 174px;
      .smile-logo {
        width: 294px;
        height: 266.7px;
        object-fit: contain;
      }
      .header-wrap {
        margin-top: 100px;
        .login-info {
          font-size: 75px;
          line-height: 1.33;
          .logout-wrap {
            width: 333px;
            height: 114px;
            border: solid 3px #ffffff;
            font-size: 61px;
            line-height: 114px;
            margin: 80px auto 0;
          }
        }
      }
      .login-wrap {
        font-size: 73px;
        .login-btn {
          width: 613px;
          height: 114px;
          line-height: 114px;
          background-color: #ffffff;
          font-size: 46px;
          margin: 120px auto 0;
          .google-btn {
            width: 87.1px;
            height: 88.2px;
            object-fit: contain;
            vertical-align: middle;
            margin-right: 20px;
          }
          #leftMenuGoogleLogin {
            color: #707070;
          }
        }
      }
      .menu-wrap {
        margin-top: 100px;
        .menu-content {
          height: 220px;
          line-height: 220px;
          border-bottom: solid 1px #ffffff;
          font-size: 73px;
        }
        .menu-bottom {
          font-size: 46px;
          height: 150px;
          line-height: 150px;
          & a {
            margin: 0 20px 0 20px;
          }
        }
      }
    }
  }
}
div[data-simplersidebar="mask"] {
  overflow: hidden;
  position: absolute !important;
  left: 0;
  top: 0;
}
#blurSvg {
  width: 0;
  height: 0;
}
.sidebar-blur:before {
  content: '';
  position: absolute;
  top: 0;
  left:0;
  right:0;
  bottom:0;
  background: inherit;
  z-index: -1;
}